<template>
    <div class="p-2" v-if="isPermission">
        <div class="w-100 text-left">
            <CButton @click="resetForm()" class="row" color="light"> <i class="fi fi-rr-arrow-left mr-2"></i>{{
                $t('back') }}</CButton>
        </div>
        <div class="w-100 text-center ">
            <h3 class="mt-2">{{ $t('inputPromotionDetails') }}</h3>
            <span style="color: gray;" class="mr-5 ml-4">{{ $t('inputPromotionDetailsDesp') }}
            </span>
        </div>
        <div class="pl-2 pr-2 w-100">
            <div class="mt-5" v-if="conditionType !== 'NO_CONDITION'">
                <h5> <span class="text-danger">*</span> {{ $t('conditionHeader') }} : {{ $t(conditionType) }}</h5>
                <span style="color: gray;">{{ $t(getDescription(conditionType)) }}
                </span>
                <AppPromotionConditionForm ref="conditionComponent" key="AppPromotionConditionForm1"
                    :conditionType="conditionType" />
            </div>
            <div class="mt-5">
                <h5> <span class="text-danger">*</span> {{ $t('promotionHeader') }} : {{ $t(promotionType) }}</h5>
                <span style="color: gray;">{{ $t(getDescription(promotionType)) }}
                </span>
                <AppPromotionResultForm :isGrabTemplate="isGrabTemplate" ref="resultsComponent"
                    :promotionType="promotionType" />
            </div>
            <div v-if="!isGrabPromotionEligible()">
                <div v-if="conditionType == 'NO_CONDITION'" class="mt-4 d-flex justify-content-between">
                    <div>
                        <h5>{{ $t('conditionHeader') }} ({{ $t('optionalChoice') }})</h5>
                        <span style="color: gray;">{{ $t('PRICE_RECEIPT_EXCEED_DESP') }}
                        </span>
                    </div>
                    <div class="pr-3">
                        <CSwitch :checked.sync="isConditionRequired" color="success"></CSwitch>
                    </div>
                </div>
                <div v-if="conditionType == 'NO_CONDITION' && isConditionRequired">
                    <AppPromotionConditionForm ref="conditionComponent" key="AppPromotionConditionForm2"
                        conditionType="PRICE_RECEIPT_EXCEED" />
                </div>
            </div>
        </div>
        <div class="row w-100">
            <AppPromotionDetailsForm ref="detailsComponent" :isEmptyForm="true"
                :isGrabPromotionAllow="isGrabPromotionEligible()" />
        </div>

        <CModal :show.sync="saveModal" centered :title="$t('confirmSavePromotionHeader')" color="success"
            header="false">
            <div class="text-center p-3">
                <h4>{{ $t('confirmCreatePromotion') }}</h4>
                <span v-if="!isGrabPromotionScope" style="color: gray" v-html="$t('confirmSavePromotionDesp')" />
                <span v-else style="color: gray" v-html="$t('createGrabPromotionDesp')" />
                <!-- <div class="json-display">
                        <pre>{{ prettyJson(promotionData) }}</pre>
                    </div> -->
            </div>
            <template #footer>
                <div class="w-100 d-flex justify-content-center">
                    <CButton :disabled="loading" style="width: 50%;margin-right: 1cap;" @click="saveModal = false"
                        color="secondary">
                        <b>{{ $t("cancel") }}</b>
                    </CButton>
                    <CButton :disabled="loading" style="width: 50%;margin-left: 1cap;" @click="savePromotion()"
                        color="success">
                        <CSpinner v-if="loading" color="white" size="sm" />
                        <b>{{ $t("submit") }}</b>
                    </CButton>
                </div>
            </template>
        </CModal>
        <CModal :show.sync="alertModal" centered :title="$t('saveFailedHeader')" color="danger" header="false">
            <div class="text-center p-3">

                <h5 style="line-height: 180%;" v-html="getErrorMessage()" />
                <span v-if="errorMessage !== 'validateInfomation'" style="color: gray">({{ errorMessage }})</span>
            </div>
            <template #footer>
                <div class="w-100 d-flex justify-content-center">
                    <CButton style="width: 100%;margin-left: 1cap;" @click="alertModal = false" color="light">
                        <CSpinner v-if="loading" color="white" size="sm" />
                        <b>{{ $t("agreed") }}</b>
                    </CButton>
                </div>
            </template>
        </CModal>

        <hr />
        <div class="mt-3">
            <CButton @click="toggleSaveModal()" block color="success">{{ $t('save') }}</CButton>
        </div>
    </div>
    <div v-else>
        <access-data></access-data>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import permis from '@/util/permission'
import AppPromotionConditionForm from '@/components/AppPromotionConditionForm';
import AppPromotionResultForm from '@/components/AppPromotionResultForm';
import AppPromotionDetailsForm from '@/components/AppPromotionDetailsForm';
import 'vue2-datepicker/index.css'
import 'vue2-timepicker/dist/VueTimepicker.css'
import pos from '@/services/local';
import '@/assets/styles/promotion.css'
import VueTimepicker from 'vue2-timepicker'
import util from '@/util/util'


export default {
    components: {
        VueTimepicker,
        AppPromotionConditionForm,
        AppPromotionResultForm,
        AppPromotionDetailsForm,
    },
    props: {
        template: Object,
    },
    data() {
        return {
            loading: false,
            saveModal: false,
            isConditionRequired: false,
            promotion: {
                type: '',
                condition: '',
                name: '',
                description: '',
                img: '',
            },
            promotionData: {},
            orderShopSetting: {},
            alertModal: false,
            errorMessage: '',
            showResponseError: false,
        }
    },
    computed: {
        ...mapGetters(['shops', 'users', 'date', 'permissions']),
        isPermission() {
            // return permis.findPermissionRead('inventory', this.$route.path)
            const shopObjectId = localStorage.getItem('shopObjectId');
            const selectedShop = this.shops.find(shop => shop.objectId === shopObjectId) || null
            return selectedShop?.currentPlan == "multiplePOS" || false
        },
        shopObjectId() {
            return localStorage.getItem('shopObjectId')
        },
        uid() {
            return localStorage.getItem('uid')
        },
        conditionType() {
            return this.template.condition?.type || ''
        },
        promotionType() {
            return this.template.type || ''
        },
        isGrabTemplate() {
            return this.template.isGrabTemplate || false
        },
        isGrabPromotionScope() {
            return this.promotionData?.scopes?.includes('GRAB') || false
        },
    },
    created() {
        this.getOrderShopSetting()
    },
    methods: {
        resetForm() {
            this.$emit('sendTemplate');
        },
        getDescription(type) {
            return type.concat('_DESP')
        },
        async toggleSaveModal() {
            this.loading = true;
            const dataGenerated = await this.makePromotionData();
            if (dataGenerated) {
                if (this.promotionData.details.title == null || this.promotionData.details.title == '') {
                    this.promotionData.details.title = this.getSuggestPromotionTitle(this.promotionData.type, this.promotionData.results)
                }
                if (this.promotionData.details.description == null || this.promotionData.details.description == '') {
                    this.promotionData.details.description = this.getSuggestPromotionDescription(this.promotionData.condition)
                }
                if (this.promotionData.details.remoteImagePath == null || this.promotionData.details.remoteImagePath == '') {
                    this.getBase64Image(this.promotionData.type)
                }

                if (this.validatePromotionData()) {
                    this.saveModal = true;
                } else {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    this.alertModal = true
                    this.errorMessage = 'validateInfomation'
                    this.validateDetails()
                }
            } else {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
            this.loading = false;
        },
        async makePromotionData() {
            this.promotionData.type = this.template.type
            this.promotionData.results = this.$refs.resultsComponent.getResults()
            if (this.isConditionRequired || (this.conditionType !== "NO_CONDITION" && !this.isConditionRequired)) {
                this.promotionData.condition = this.$refs.conditionComponent.getCondition();
            } else if (this.conditionType === "NO_CONDITION") {
                this.promotionData.condition = {
                    type: this.conditionType,
                };
            }
            const updatedDetails = this.$refs.detailsComponent.getDetails();
            this.promotionData = {
                ...this.promotionData,
                ...updatedDetails
            }

            if (this.promotionData.type == "FREE_ITEM" && this.promotionData.scopes.includes('GRAB')) {
                if (this.promotionData.results[0].quantity !== 1) {
                    alert(this.$t('alertFreeItemGrab'))
                    return false;
                }
            }

            if (this.promotion.type == 'ITEM_X_FREE_Y') {
                let resultInput = this.$refs.resultsComponent.getResults()
                this.promotionData.condition = resultInput[0]
                this.promotionData.condition.type = this.promotion.type
            } else if (this.promotion.type == 'CATEGORY_X_FREE_Y') {
                let resultInputCategory = this.promotionData.results
                let resultData = {}

                if (resultInputCategory[0]?.categories && resultInputCategory[0]?.categories[0]?.id) {
                    resultData = {
                        "category": {
                            "id": resultInputCategory[0].categories[0].id,
                            "objectId": resultInputCategory[0].categories[0].objectId,
                            "name": resultInputCategory[0].categories[0].name,
                        },
                        "freeItemQuantity": resultInputCategory[0].freeItemQuantity,
                        "inCartQuantity": resultInputCategory[0].inCartQuantity,
                        "type": this.promotion.type,
                    }
                } else {
                    resultData = {
                        "category": {
                            "id": resultInputCategory[0].category.id,
                            "objectId": resultInputCategory[0].category.objectId,
                            "name": resultInputCategory[0].category.name,
                        },
                        "freeItemQuantity": resultInputCategory[0].freeItemQuantity,
                        "inCartQuantity": resultInputCategory[0].inCartQuantity,
                        "type": this.promotion.type,
                    }
                }
                this.promotionData.results[0] = resultData
                this.promotionData.condition = resultData
            }
            return true
        },
        validatePromotionData() {
            const isValid = Object.entries(this.promotionData).every(([key, value]) => {
                if (value === null || value === '' || value === undefined) {
                    console.warn(`Field ${key} is invalid: ${value}`);
                    return false;
                }

                // Validate nested objects (e.g., `details` and `condition`)
                if (typeof value === 'object' && !Array.isArray(value)) {
                    return Object.entries(value).every(([subKey, subValue]) => {
                        // Skip validation for `details.remoteImagePath`
                        if (key === 'details' && subKey === 'remoteImagePath') {
                            return true;
                        }
                        if (subValue === null || subValue === '' || subValue === undefined) {
                            console.warn(`Field ${key}.${subKey} is invalid: ${subValue}`);
                            return false;
                        }
                        return true;
                    });
                }

                // Validate arrays and their elements
                if (Array.isArray(value)) {
                    if (value.length === 0) {
                        console.warn(`Field ${key} is invalid: Array length is 0`);
                        return false;
                    }
                    return value.every((item, index) => {
                        if (item === null || item === '' || item === undefined) {
                            console.warn(`Field ${key}[${index}] is invalid: ${item}`);
                            return false;
                        }
                        if (typeof item === 'object') {
                            // Deep validation for objects within arrays
                            return Object.entries(item).every(([subKey, subValue]) => {

                                if (subKey !== 'quantityLimit' && subKey !== 'cap') {
                                    if (subValue === null || subValue === '' || subValue === undefined) {
                                        console.warn(`Field ${key}[${index}].${subKey} is invalid: ${subValue}`);
                                        return false;
                                    }
                                } else {
                                    if (subKey == 'quantityLimit') {
                                        item[subKey] = -1
                                    }
                                }

                                // Ensure `results.items` array is not empty
                                if (key === 'results' && subKey === 'items' && Array.isArray(subValue) && subValue.length === 0) {
                                    console.warn(`Field ${key}[${index}].${subKey} is invalid: Array cannot be empty`);
                                    return false;
                                }

                                return true;
                            });
                        }
                        return true;
                    });
                }

                return true;
            });

            if (isValid) {
                const { startDate, endDate } = this.promotionData;
                if (startDate && endDate) {
                    const start = new Date(startDate);
                    const end = new Date(endDate);

                    if (start > end) {
                        console.warn("Validation failed: startDate cannot be after endDate.");
                        return false;
                    }
                }
            }

            return isValid;
        },
        prettyJson(jsonData) {
            return JSON.stringify(jsonData, null, 2);
        },
        savePromotion() {
            this.loading = true
            const headers = {
                shopObjectId: this.shopObjectId,
                uid: this.uid,
                merchantID: this.orderShopSetting?.grab?.merchantID || null
            }
            pos
                .post('/api/v3.0/shop/promotion', this.promotionData, { headers: headers })
                .then((res) => {
                    if (res.data.success == false) {
                        this.errorMessage = res.data.message
                        this.alertModal = true
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        this.saveModal = false;
                        this.loading = false
                    } else {
                        this.$router.push('/promotion')
                        this.saveModal = false;
                        this.loading = false
                    }

                })
                .catch((error) => {
                    this.errorMessage = error
                    this.alertModal = true
                    this.saveModal = false;
                    this.loading = false
                });
        },
        isGrabPromotionEligible() {
            return this.template.isGrabTemplate || false
        },
        getOrderShopSetting() {
            const uid = this.uid
            let params = {
                shopObjectId: this.shopObjectId,
            }
            pos({
                method: 'get',
                url: '/api/v2.0/' + uid + '/OrderShopSetting',
                params: params,
                headers: { shopObjectId: this.shopObjectId }
            }).then((res) => {
                this.orderShopSetting = res?.data?.data[0] || null
            })

        },
        getErrorMessage() {
            const errorMessages = {
                '404:catagory': '404Category',
                '400:Invalid parameters!': '400InvalidParameters',
                '400:Category level campaign should have one': '400CategoryOne',
                '400:startTime has to be after now': '400StartTime',
                '404:item not found': '404Item',
                '400: campaign start time too close for now': '400CampaignStartTime',
                '400:Item(s) are on multiple promotions': '400ItemMultiple',
                '400:Promotion overlaps with other promotions ': '400PromotionOverlaps',
                '400:invalid quotas': '400InvalidQuotas',
                '400:CATEGORY_CAMPAIGN_OVERLAP': '400CategoryCampaignOverlap',
                'validateInfomation': 'validateInfomation'
            };

            for (const [key, message] of Object.entries(errorMessages)) {
                if (this.errorMessage.startsWith(key)) {
                    return this.$t(`errorMessagesGrab.${message}`)
                }
            }
            return this.$t('errorMessagesGrab.unknownError')
        },
        getSuggestPromotionTitle(type, results) {
            const title = util.generatePromotionResultTitle(type, results)
            return title
        },
        getSuggestPromotionDescription(condition) {
            const description = util.generatePromotionConditionTitle(condition)
            return description
        },
        getBase64Image(type) {
            const imagePath = `/img/promotion/type/${type}.jpg`;
            fetch(imagePath)
                .then(response => response.blob())
                .then(blob => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        this.promotionData.details.remoteImagePath = reader.result; // Base64 string
                    };
                    reader.readAsDataURL(blob);
                })
                .catch(error => {
                    this.promotionData.details.remoteImagePath = ''
                    console.error('Error loading image as Base64:', error);
                });
        },
        validateDetails() {
            if (this.promotionData.details.title.includes('null')) {
                this.promotionData.details.title = '';
            }
            if (this.promotionData.details.description.includes('null')) {
                this.promotionData.details.description = '';
            }
        }
    },
};
</script>
<style></style>